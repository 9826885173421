import React from "react";
import { Link } from "gatsby";
import { getFeaturedImgSrc } from "../utils";

const RelatedArticles = (props) => {
  const { articles, sub_category } = props;

  if (articles.length === 0) return null;

  return (
    <div className="uk-margin-large-top">
      <h2
        style={{
          textAlign: "center",
          paddingTop: "40px",
          borderTop: "1px solid",
        }}
      >{`more ${sub_category.name} posts`}</h2>
      <div
        className="realted-articles-container"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill,minmax(300px,1fr))",
          gridGap: "20px",
        }}
      >
        {articles.map((article, i) => {
          return (
            <div key={article.node.strapiId}>
              <div className="uk-inline" style={{ width: "100%" }}>
                <Link to={`/article/${article.node.slug}`}>
                  <img
                    src={getFeaturedImgSrc(article)}
                    alt={article.node.title}
                  />
                  <div className="uk-overlay uk-overlay-primary uk-position-bottom">
                    <p>{article.node.title}</p>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RelatedArticles;
