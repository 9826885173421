import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import UpDownVote from "./upDownVote";
import AffiliateLink from "./affiliateLink";
import "../assets/css/product.css";

const ProductInPost = (props) => {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  const product = props.item;
  const recommendedIcon = product.recommended ? (
    <FontAwesomeIcon icon={faStar} />
  ) : (
    ""
  );

  return (
    <div
      className="uk-child-width-expand@s"
      uk-grid="uk-grid"
      key={isClient ? "client" : "server"}
    >
      <div>
        <div className="uk-card uk-card-default">
          <div className="uk-card-media-top card-img-container">
            <img
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
              src={
                product.isAmazonProduct
                  ? `https://m.media-amazon.com/images/I/${product.imgSrc}._SL800_.jpg`
                  : product.imgSrc
              }
              alt={product.name}
            />
            <span>{recommendedIcon}</span>
          </div>

          <div
            className="uk-card-footer"
            style={{
              display: "grid",
              gridTemplateColumns: "2fr 3fr",
              alignItems: "center",
            }}
          >
            <UpDownVote id={product.id} isClient={isClient} />
            <AffiliateLink
              product={product}
              className="uk-button uk-button-primary"
            >
              Check Price
            </AffiliateLink>
          </div>
        </div>
      </div>
      <div>
        <div className="title">
          <h3>{product.name}</h3>
        </div>
        <div className="high-light">
          <p>{product.description}</p>
        </div>
        <div className="features">
          <ul>
            {product.features.map((f, index) => {
              return (
                <li key={index}>
                  <span>{f}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductInPost;
