import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import ProductInPost from "../components/productInPost";
import RelatedArticles from "../components/relatedArticles";
import Markdown from "react-markdown";
//import configData from "../../config.json";

export const query = graphql`
  query ArticleQuery($slug: String!, $sub_category_slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      strapiId
      title
      description
      content
      published_at
      category {
        slug
        name
      }
      sub_category {
        slug
        name
      }
      products {
        id
        name
        description
        isAmazonProduct
        imgSrc
        affiLink
        isAffiLinkDead
        features
        recommended
      }
    }
    related: allStrapiArticle(
      filter: { sub_category: { slug: { eq: $sub_category_slug } } }
      sort: { fields: [created_at], order: DESC }
    ) {
      edges {
        node {
          strapiId
          description
          slug
          title
          products {
            name
            imgSrc
            affiLink
            isAmazonProduct
            recommended
          }
        }
      }
    }
  }
`;

const Article = ({ data }) => {
  const article = data.strapiArticle;
  const relatedArticles = data.related.edges.filter((x) => {
    return x.node.strapiId !== article.strapiId;
  });

  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
  };

  return (
    <Layout seo={seo}>
      <div className="uk-section">
        <div className="uk-container">
          <h1>{article.title}</h1>
          <Markdown source={article.content} escapeHtml={false} />
          <div className="uk-margin-large-top post-products">
            {article.products.map((item, index) => {
              return <ProductInPost item={item} index={index} key={item.id} />;
            })}
          </div>

          <div className="uk-text-center uk-margin-large-top">
            <Link
              to={`/products?cat=${article.category.slug}&tag=${article.sub_category.slug}`}
              className="uk-button uk-button-secondary uk-text-nowrap uk-button-large uk-width-1-1 uk-width-1-3@m"
            >
              {`shop all from ${article.sub_category.name}`}
            </Link>
          </div>

          <RelatedArticles
            articles={relatedArticles}
            sub_category={article.sub_category}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Article;
